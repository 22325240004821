
import EthereumSession from './eth-session';
import overwatchABI from './overwatch-abi';
import erc721ABI from './erc721-abi';

const config = {
  //baseURL:          'http://localhost:3001',
  baseURL:          'https://eject.foolprooflabs.io',
  chain:             EthereumSession.COMMON_CHAINS[31337],
  infuraId:          process.env.INFURA_ID,
  network:          'devnet',

  overwatchAddress: '0x004223A05735A8942C75Cd4539C5395054464db0',
  overwatchABI:      overwatchABI,

  erc721ABI:         erc721ABI
};

config.networkUrl = `https://devnet.foolprooflabs.io`;

export default config;
