
import React from 'react';
import Web3 from 'web3';
import './App.css';

import erc721ABI from './lib/erc721-abi';
import config from './lib/config';
import EthereumSession from './lib/eth-session';

var BigInt = window.BigInt;
var BigZero = BigInt(0);

const collabs = [
  // default
  {
    collection: "0x0000000000000000000000000000000000000000",
    ethPrice: "11000000000000000",
  },
  // punkin spicies
  {
    collection: "0x34625Ecaa75C0Ea33733a05c584f4Cf112c10B6B",
    ethPrice: "8250000000000000",
  }
];

function App() {
  const [account, setAccount] = React.useState(null);
  //const [provider, setProvider] = React.useState(null);

  const [collections, setCollections] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);
  const [isStep1Expanded, setStep1Expanded] = React.useState(true);
  const [isStep2Expanded, setStep2Expanded] = React.useState(true);
  const [isStep3Expanded, setStep3Expanded] = React.useState(true);
  const [isStep4Expanded, setStep4Expanded] = React.useState(true);

  const [approvalStatus, setApprovalStatus] = React.useState({});
  const [rescueWallet, setRescueWallet] = React.useState('0x0000000000000000000000000000000000000000');
  const [selection, setSelection] = React.useState([]);
  const [registeredTokens, setRegisteredTokens] = React.useState({});

  const [approvalTransaction, setApprovalTransaction] = React.useState(null);
  const [rescueTransaction, setRescueTransaction] = React.useState(null);
  const [tokensTransaction, setTokensTransaction] = React.useState(null);
  const [walletTransaction, setWalletTransaction] = React.useState(null);


  const overwatchSession = React.useMemo(() => {
    return new EthereumSession({
      chain: config.chain,
      contractABI: config.overwatchABI,
      contractAddress: config.overwatchAddress,
    });
  }, []);


  const formatPrice = eth_price => {
    if(eth_price > BigZero)
      return Web3.utils.fromWei(String(eth_price)) +' Ξ';
    else
      return '-';
  };


  // always cache
  const handleConnect = async (deep) => {
    // TODO: web3 modal

    if(await overwatchSession.connectWeb3(deep)){
      handleConnected();

      //overwatchSession.wallet.on( 'accountsChanged', handleConnected);
      return true;
    }

    return false;
  };

  // don't cache
  const handleConnected = async () => {
    const tmpAccount = overwatchSession?.wallet?.accounts?.[0];
    setAccount(tmpAccount ?? null);
    handleLoadWallet(tmpAccount);
  };

  // don't cache
  const handleLoadTokens = async () => {
    if(!collections?.length)
      return;


    // get 10 tokens and request the floor
    const selected = [];
    for(let c of collections){
      if(typeof c.eth_price !== 'bigint'){
        selected.push(c.collection);
        if(selected.length >= 10)
          break;
      }
    }

    if(selected.length === 0){
      console.info('all collections loaded');
      setIsLoading(false);
      return;
    }


    //TODO: session storage
    const newCollections = [...collections];
    const response = await fetch(`${config.baseURL}/floor?collections=${selected.join('&collections=')}`);
    if(response.ok){
      const data = await response.json();
      for(let c of data){
        const found = newCollections.find(nc => nc.collection === c.collection);
        if(found){
          c.eth_price = BigInt(c.eth_price);
          Object.assign(found, c);
        }
        else{
          console.warn(`Can't update ${c.collection}`);
        }
      }
      

      newCollections.sort((left, right) => {
        if(left.eth_price < right.eth_price)
          return 1;

        if(left.eth_price > right.eth_price)
          return -1;

        return 0;
      });
    }
    else{
      console.warn(`${response.status}: ${response.statusText}`);
    }

    //delay
    //setTimeout(() => setCollections(newCollections), 10000);
    setCollections(newCollections);
  };

  // cache for account
  const handleLoadWallet = async (account) => {
    if(!account)
      return;


    const tmpRescueWallet = await overwatchSession.contract.methods.rescueWallet(account).call();
    if(rescueWallet !== tmpRescueWallet){
      setRescueWallet(tmpRescueWallet);
    }



    const tokenTree = {};
    const collections = await overwatchSession.contract.methods.getWalletCollections(account).call();
    const tokens = await overwatchSession.contract.methods.getWalletTokens(account, collections).call();
    for(let token of tokens){
      const collection = token.collection.toLowerCase();
      if(collection in tokenTree){
        tokenTree[collection][token.tokenId] = true;
      }
      else{
        tokenTree[collection] = {
          [token.tokenId]: true
        };
      }
    }
    setRegisteredTokens(tokenTree);


    // check for approvals
    const tmpApprovalStatus = {};
    const promises = collections.map(c => {
      const collection = c.toLowerCase();
      const contract = new overwatchSession.web3client.eth.Contract(config.erc721ABI, collection);
      return contract.methods.isApprovedForAll(account, overwatchSession.contractAddress).call()
        .then(isApproved => {
          tmpApprovalStatus[collection] = isApproved;
        });
    });

    await Promise.all(promises);
    setApprovalStatus(tmpApprovalStatus);


    //TODO: session storage
    const response = await fetch(`${config.baseURL}/wallet?account=${account}&t=${Date.now()}`);
    if(response.ok){
      const data = await response.json();
      setCollections(data);
      setIsLoading(true);
    }
    else{
      console.warn(`${response.status}: ${response.statusText}`);
    }
  };

  const handleRescue = async (evt) => {
    try{
      if(!account){
        alert("Please connect first");
        return;
      }

      const gasLimit = await overwatchSession.contract.methods.rescueCollections([]).estimateGas({
        from: account,
      });

      const gas = BigInt(gasLimit) * BigInt(11) / BigInt(10);
      await overwatchSession.contract.methods.rescueCollections([]).send({
        from: account,
        gas: String(gas)
      }, (err, txnHash) => {
        setRescueTransaction(txnHash);
      });
    }
    catch(err){
      handleEthError(err);
    }
  };

  const handleRescueWallet = (evt) => {
    setRescueWallet(evt.target.value);
  };

  const handleStep1Toggle = (evt) => {
    setStep1Expanded(isExpanded => !isExpanded);
  };

  const handleStep2Toggle = (evt) => {
    setStep2Expanded(isExpanded => !isExpanded);
  };

  const handleStep3Toggle = (evt) => {
    setStep3Expanded(isExpanded => !isExpanded);
  };

  const handleStep4Toggle = (evt) => {
    setStep4Expanded(isExpanded => !isExpanded);
  };

  const handleTokenSelected = (evt, collection, tokenId) => {
    const newSelection = [...selection];
    const foundAt = newSelection.findIndex(s => s.collection === collection && s.tokenId === tokenId);
    if(foundAt > -1){
      newSelection.splice(foundAt, 1);
    }
    else{
      newSelection.push({
        collection,
        tokenId
      });
    }

    newSelection.sort((left, right) => {
      if(left.collection < right.collection)
        return -1;

      if(left.collection > right.collection)
        return 1;

      if(left.tokenId < right.tokenId)
        return -1;

      if(left.tokenId > right.tokenId)
        return 1;

      return 0;
    });
    setSelection(newSelection);
  };


  // transactions
  const handleEthError = (err) => {
    const ethErr = EthereumSession.getError(err);
    if (ethErr.code === 4001){
      // use cancelled
      return;
    }

    if (ethErr.code === -32000) {
      alert("Not enough ETH in wallet");
      return;
    }

    if(ethErr.data && overwatchSession.errors[ethErr.data])
      ethErr.message = overwatchSession.errors[ethErr.data].name;
    
    if (ethErr.message) {
      const check = "execution reverted: ";
      if (ethErr.message.indexOf(check) !== -1)
        alert(ethErr.message.substring(check.length));
      else
        alert(ethErr.message);
      return;
    }

    alert(JSON.stringify(ethErr));
  };

  const handleApproval = async (evt, collection) => {
    try{
      if(!account){
        alert("Please connect first");
        return;
      }


      const contract = new overwatchSession.web3client.eth.Contract(config.erc721ABI, collection);
      const gasLimit = await contract.methods.setApprovalForAll(overwatchSession.contractAddress, true)
        .estimateGas({
          from: account
        });

        //TODO: Ledger support, type 0x1
      const gas = BigInt(gasLimit) * BigInt(11) / BigInt(10);
      await contract.methods.setApprovalForAll(overwatchSession.contractAddress, true).send({
        from: account,
        gas: String(gas),
      }, (err, txnHash) => {
        setApprovalTransaction(txnHash);
      });

      //setStep2Expanded(false);
    }
    catch(err){
      handleEthError(err);
    }
  };

  const handleRegisterTokens = async (evt) => {
    try{
      if(!account){
        alert("Please connect first");
        return;
      }


      const collectionsTokens = selection.reduce((acc, val) => {
        if(acc[val.collection])
          acc[val.collection].push(val.tokenId);
        else
          acc[val.collection] = [val.tokenId];

        return acc;
      }, {});


      const collections = Object.keys(collectionsTokens);
      const tokenIds = Object.values(collectionsTokens);
      const gasLimit = await overwatchSession.contract.methods.registerTokens(collections, tokenIds).estimateGas({
        from: account
      });

      const gas = BigInt(gasLimit) * BigInt(11) / BigInt(10);
      await overwatchSession.contract.methods.registerTokens(collections, tokenIds).send({
        from: account,
        gas: String(gas),
      }, (err, txnHash) => {
        setTokensTransaction(txnHash);
      });

      //setStep2Expanded(false);
      alert('Tokens registered');
    }
    catch(err){
      handleEthError(err);
    }
  };

  const handleRegisterWallet = async (evt) => {
    try{
      if(!account){
        alert("Please connect first");
        return;
      }


      const checks = collabs.map(c => {
        if(c.collection === '0x0000000000000000000000000000000000000000'){
          return Promise.resolve({
            balance: 1,
            collection: c.collection,
            ethPrice: BigInt(c.ethPrice)
          });
        }

        const contract = new overwatchSession.web3client.eth.Contract(erc721ABI, c.collection);
        return contract.methods.balanceOf(account).call()
          .then(balance => ({
            balance: parseInt(balance),
            collection: c.collection,
            ethPrice: BigInt(c.ethPrice)
          }));
      });
      let results = await Promise.all(checks);
      results = results.filter(r => r.balance > 0);
      results.sort((a, b) => {
        if(BigInt(a.ethPrice) < BigInt(b.ethPrice))
          return -1;

        if(BigInt(a.ethPrice) > BigInt(b.ethPrice))
          return 1;

        return 0;
      });

      let collab = "0x0000000000000000000000000000000000000000";
      if(results.length > 0){
        collab = results[0].collection;
      }


      const ethPrice = await overwatchSession.contract.methods.getPrice(collab).call({
        from: account
      });

      const gasLimit = await overwatchSession.contract.methods.setRescueWallet(rescueWallet, collab).estimateGas({
        from: account,
        value: String(ethPrice)
      });

      const gas = BigInt(gasLimit) * BigInt(11) / BigInt(10);
      await overwatchSession.contract.methods.setRescueWallet(rescueWallet, collab).send({
        from: account,
        gas: String(gas),
        value: String(ethPrice)
      }, (err, txnHash) => {
        setWalletTransaction(txnHash);
      });

      setStep1Expanded(false);
    }
    catch(err){
      handleEthError(err);
    }
  };


  //TODO: load my current selection


  // run once
  React.useEffect(() => {
    handleConnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if account changes...
  React.useEffect(() => {
    handleLoadWallet(account);

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);


  React.useEffect(() => {
    handleLoadTokens();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collections]);

  return (
    <div id="app">
      <header>
        <h1>Overwatch</h1>
        <div style={{ textAlign: 'right' }}>{account}</div>
        <div style={{ textAlign: 'right' }}>
          <button onClick={() => handleConnect(true)}>Connect</button>
        </div>
      </header>
      <main>
        <fieldset>
          <legend onClick={handleStep1Toggle}>Step 1: Register Tokens</legend>

          <div style={{ display: (isStep1Expanded ? 'block' : 'none')}}>
            {selection.length ? <div><button onClick={handleRegisterTokens}>Register {selection.length} Token(s)</button></div> : null}
            {tokensTransaction ? <div>Transaction: {tokensTransaction}</div> : null}

            {collections?.map(c => {
              const collection = c.collection.toLowerCase();

              return c.tokens?.map(tokenId => {
                const isRegistered = !!registeredTokens[collection]?.[tokenId];
                let classNames = isRegistered ? "registered " : "";

                const isSelected = selection.find(s => s.collection === collection && s.tokenId === tokenId);
                classNames += isSelected ? "selected " : "";

                return (
                  <div
                    key={`${collection}-${tokenId}`}
                    className={`card ${classNames}`}
                    onClick={evt => handleTokenSelected(evt, c.collection, tokenId)}>
                      <h3>{c.name ?? '(unknown)'}</h3>
                      <strong>#{tokenId}</strong><br />
                      <strong>Floor Price:</strong> {formatPrice(c.eth_price)}<br />
                      <br />
                      {c.image && 
                        <div>
                          <img alt={(c.name ?? 'Token') +' #'+ tokenId} src={c.image} />
                        </div>
                      }
                  </div>
                );
              });
            })}
          </div>
        </fieldset>
        <hr />

        <fieldset>
          <legend onClick={handleStep2Toggle}>Step 2: Approve Contract</legend>

          <div style={{ display: (isStep2Expanded ? 'block' : 'none')}}>
           {approvalTransaction && <div>Transaction: {approvalTransaction}</div>}
            <ul>
            {approvalStatus && Object.entries(approvalStatus).map(([collection, isApproved]) => {
              //TODO: name
              //TODO: token count
              return (
                <li key={collection}>{collection}: {isApproved ? <span>Approved!</span> : <button onClick={evt => handleApproval(evt, collection)}>Approve</button>}</li>
              );
            })}
            </ul>
          </div>
        </fieldset>
        <hr />

        <fieldset>
          <legend onClick={handleStep3Toggle}>Step 3: Set Rescue Wallet</legend>

          <div style={{ display: (isStep3Expanded ? 'block' : 'none')}}>
            {walletTransaction && <><br />Transaction: {walletTransaction}<br /></>}
            <label htmlFor="rescue-wallet">Rescue Wallet:</label>
            {' '}<input type="text" id="rescue-wallet" name="rescue-wallet" value={rescueWallet} maxLength="42" size="44" onChange={handleRescueWallet} />
            {/* TODO: is valid */}
            <button onClick={handleRegisterWallet}>Register Safe Wallet</button>
          </div>
        </fieldset>


        <fieldset>
          <legend onClick={handleStep4Toggle}>Step 4: Rescue Tokens</legend>

          <div style={{ display: (isStep4Expanded ? 'block' : 'none')}}>
            {rescueTransaction && <><br />Transaction: {rescueTransaction}<br /></>}
            <button onClick={handleRescue}>Rescue</button>
          </div>
        </fieldset>
      </main>
      <footer></footer>
    </div>
  );
}

export default App;
